<template>
  <div class="parkingGuide needsclick">
    <header>
      <div>
        <img @click="handleBack" src="@/assets/imgs/back-icon.png" alt="" />
        <span>风险描述</span>
        <!-- <span v-else>新增风险描述</span> -->
      </div>
    </header>
    <div class="addressInfo" v-if="stationName">
      <img
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/address.png"
        alt=""
      />
      <div>
        <div>{{ stationName }}</div>
        <div>ID {{ stationId }}</div>
      </div>
    </div>
    <div v-if="noneData && !editing" class="noneData">
      <img
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/noneData.png"
        alt=""
      />
      <div>暂无风险描述</div>
      <!-- <div>利用图文指引，帮助无人车识别停靠位置，<br />更精准的完成泊车</div> -->
    </div>
    <div v-if="editing || list.length > 0" class="guideCard">
      <div v-if="editing" class="uploadTips">
        <span>上传/拍摄图片</span>
        <span>(最多{{ max }}张)</span>
        <van-popover
          v-model="showPopover"
          trigger="click"
          placement="bottom-start"
          theme="dark"
          get-container=".parkingGuide"
        >
          <span class="tipContent"> 该图片上传后，可帮助无人车识别停靠位置，更精准的完成泊车 </span>
          <template #reference>
            <img
              class="needsclick"
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/question.png"
              alt=""
            />
          </template>
        </van-popover>
      </div>
      <div v-for="(item, index) in list" :key="item.key" class="uploadListItem">
        <div class="uploadedPic">
          <div v-if="item.loading" class="uploadLoading">
            <img
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/loading.png"
              alt=""
            />
          </div>
          <template v-if="item.url">
            <img
              v-if="editing"
              class="needsclick"
              @click="handleUploadClick($event, index)"
              :src="item.url"
              alt=""
            />
            <img
              v-else
              class="needsclick"
              @click="handleImagePreview(index)"
              :src="item.url"
              alt=""
            />
          </template>
        </div>
        <div class="description">
          <textarea
            @focus="handleInputFocus"
            @input="handleDescriptionChange($event, index)"
            v-if="editing"
            :value="item.description"
            maxlength="20"
            placeholder="可添加文字描述，最多20个字"
          ></textarea>
          <div v-else :class="{ noneDesc: !item.description }">
            {{ item.description || '暂无描述' }}
          </div>
        </div>
        <div v-if="editing && list.length > 1" class="deleteUploaded">
          <img
            @click="handleDeleteItem(index, item)"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/item_delete.png"
            alt=""
          />
        </div>
      </div>
      <div v-if="editing && list.length < max" class="uploadTrigger">
        <img
          class="needsclick"
          @click="handleUploadClick"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/uploader.png"
          alt=""
        />
      </div>
    </div>
    <div v-if="editing && hideshow" class="bottomBtn submitBtn">
      <div v-if="!changed" class="disabled">提交</div>
      <div v-else @click="submitUpload">提交</div>
    </div>
    <div v-if="!noneData && !editing && type === 0" class="bottomBtn editBtn">
      <div @click="editing = true">编辑</div>
    </div>
    <van-uploader
      :before-read="onBeforeRead"
      :after-read="onAfterRead"
      ref="uploader"
      :max-count="1"
      ><span></span
    ></van-uploader>
  </div>
</template>

<script>
import NDialog from '@/components/NDialog';
import { ImagePreview } from 'vant';
import { requestCameraPermission } from '@/utils';
import {
  uploadPicture,
  // deletePicture,
} from '@/api/apiv2';
import bus from '@/utils/bus';

export default {
  components: {},
  data() {
    return {
      stationId: null,
      stationName: null,
      noneData: false,
      list: [],
      editing: false,
      activeIndex: null,
      uploadFrom: 0, // 0 create; 1 update
      showPopover: false, // 提示
      changed: false, // 是否编辑过
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      hideshow: true,
      msg: '',
      max: 4,
      uploading: false, // 是否上传中
      type: 0, // 0: 新增 1: 编辑
    };
  },
  watch: {
    showHeight: function () {
      // 奇葩问题, 输入法展开和收起页面高度不一致，IOS会出现偏差
      if (this.docmHeight > this.showHeight + 3) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
      }
    },
    editing: {
      handler(newValue) {
        if (newValue) {
          this.stopWatchChange = this.$watch(
            'list',
            () => {
              this.changed = true;
            },
            { deep: true }
          );
        } else {
          this.stopWatchChange?.();
          this.changed = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleInputFocus(event) {
      const target = event.target;
      setTimeout(() => {
        target.scrollIntoView(true);
      }, 100);
    },
    async handleBack() {
      if (this.editing) {
        if (this.uploading) {
          this.$toast('图片上传中，请稍候');
          return;
        }
        if (this.changed) {
          const result = await NDialog.confirm({
            message: '内容未保存，是否确定退出？',
          });
          if (result === 'confirm') {
            // this.editing = false;
            this.$router.back();
          }
        } else {
          this.editing = false;
          this.$router.back();
        }
      } else {
        this.$router.back();
      }
    },

    handlePopoverClick() {
      this.showPopover = true;
    },
    handleUploadClick(event, index) {
      if (this.uploading) {
        this.$toast('图片上传中，请稍候');
        return;
      }
      if (index !== undefined) {
        this.activeIndex = index;
        this.uploadFrom = 1;
      } else {
        this.activeIndex = this.list.length;
        this.uploadFrom = 0;
      }
      if (process.env.NODE_ENV === 'development') {
        this.clicked = true;
        const uploaderIpt = this.$refs['uploader'].$el.querySelector('input[type=file]');
        uploaderIpt.click();
      }
      requestCameraPermission();
    },
    async onAfterRead(file) {
      if (file.file.size / 1024 / 1024 > 10) {
        this.$toast('请上传10MB以内的图片');
        return;
      }
      this.uploading = true;
      if (!this.uploadFrom) {
        this.list.push({
          url: '',
          loading: true,
          description: '',
          stationId: this.stationId,
          key: performance.now(),
        });
      } else {
        this.$set(this.list[this.activeIndex], 'loading', true);
      }
      const formData = new FormData();
      formData.append('file', file.file);
      const url = await uploadPicture(formData).catch((error) => {
        // 超时可能会引起error
        this.$toast('图片上传超时，请重试');
        this.uploading = false;
        this.handleDeleteItem(this.activeIndex);
        console.log(error);
      });

      this.$set(this.list[this.activeIndex], 'url', url);
      this.$set(this.list[this.activeIndex], 'loading', false);
      this.uploading = false;
    },
    async onBeforeRead() {
      // uploadFrom true 新增，false 修改
    },
    handleDescriptionChange(event, index) {
      this.$set(this.list[index], 'description', event.target.value);
    },
    handleDeleteItem(index, item) {
      if (item.loading) {
        this.$toast('图片上传中，请稍候');
        return;
      }
      this.list.splice(index, 1);
    },
    async submitUpload() {
      if (this.uploading) {
        this.$toast('图片上传中，请稍候');
        return;
      }
      const pictures = this.list.map((item) => ({
        ...item,
        url: item.url.replace(/\?.*/, ''),
      }));
      bus.emit('onAddRiskInfo', pictures);
      this.$router.back();
    },
    handleImagePreview(startPosition) {
      const images = this.list.map((item) => item.url);
      ImagePreview({ images, startPosition });
    },
    initDataAndStatus() {
      // type 0 新增 1修改
      const { stationId, stationName, type = 0, pictures = [] } = this.$route.query;
      // if (type === 0 && pictures.length <= 1) {
      //   // 新增只能传一张
      //   this.max = 1;
      // } else {
      //   this.max = 4;
      // }
      this.max = pictures.length > 1 ? 4 : 1;
      this.type = type;
      this.stationId = stationId;
      this.stationName = stationName;
      // 新增的时候，如果没有图片，则默认编辑
      if (type === 0) {
        if (pictures.length) {
          this.list = pictures;
          this.editing = false;
        } else {
          this.editing = true;
        }
      } else {
        if (!pictures.length) {
          this.noneData = true;
        } else {
          this.list = pictures;
        }
      }
    },
  },
  computed: {},
  created() {
    this.initDataAndStatus();
  },
  mounted() {
    window.cameraPermission = () => {
      this.clicked = true;
      const uploaderIpt = this.$refs['uploader'].$el.querySelector('input[type=file]');
      uploaderIpt.click();
    };
    window.addEventListener('resize', () => {
      this.showHeight = document.body.clientHeight;
    });
  },
  beforeDestroy() {
    window.cameraPermission = null;
  },
};
</script>

<style lang="scss" scoped>
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.parkingGuide {
  font-size: initial;
  position: relative;
  height: 100vh;
  header {
    background: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
    padding-top: var(--appBarHeight);
    > div {
      width: 100%;
      height: 40px;
      line-height: 40px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .addressInfo {
    background: white;
    display: flex;
    padding: 8px 12px;
    img {
      width: 22px;
      height: 22px;
      margin-right: 2px;
    }
    > div {
      > div {
        &:nth-child(1) {
          font-size: 16px;
          font-weight: 400;
        }
        &:nth-child(2) {
          font-size: 14px;
          font-weight: 400;
          color: rgba(151, 152, 171, 1);
        }
      }
    }
  }

  ::v-deep .van-popover__content {
    padding: 10px;
    width: 176px;
    .tipContent {
      box-sizing: border-box;
      font-size: unset;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: white;
    }
  }

  .noneData {
    padding-top: 90px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 160px;
      height: 160px;
    }
    > div {
      &:nth-child(2) {
        // color: red;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
      &:nth-child(3) {
        text-align: center;
        font-size: 14px;
        margin-top: 4px;
        margin-bottom: 30px;
      }
      &:nth-child(4) {
        width: 158px;
        height: 40px;
        opacity: 1;
        border-radius: 24px;
        background: rgba(67, 110, 255, 1);
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 40px;
        color: white;
      }
    }
  }

  .guideCard {
    box-sizing: border-box;
    margin: 15px;
    padding: 15px;
    background: white;
    border-radius: 12px;
    .uploadTips {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      > span {
        &:nth-child(1) {
          font-size: 16px;
          font-weight: 400;
        }
        &:nth-child(2) {
          margin-left: 6px;
          font-size: 12px;
          font-weight: 400;
        }
      }
      img {
        width: 14px;
        height: 14px;
        margin-left: 4px;
      }
    }

    .uploadListItem {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .uploadedPic {
        width: 86px;
        height: 86px;
        overflow: hidden;
        margin-right: 6px;
        position: relative;
        flex-shrink: 0;
        img {
          width: 86px;
          height: 86px;
          object-fit: cover;
          border-radius: 6px;
        }
        .uploadLoading {
          position: absolute;
          width: 86px;
          height: 86px;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 22px;
            height: 22px;
            animation: spin 1s infinite linear;
          }
        }
      }

      .description {
        height: 86px;
        margin-right: 6px;
        flex-grow: 1;
        > div {
          word-break: break-word;
        }
        textarea {
          width: 100%;
          height: 86px;
          padding: 8px;
          box-sizing: border-box;
          border-radius: 6px;
          background: rgba(244, 247, 249, 1);
          border: 1px solid rgba(234, 238, 238, 1);
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0px;
          resize: none;
          &::placeholder {
            color: rgba(151, 152, 171, 1);
          }
        }
        .noneDesc {
          color: rgba(151, 152, 171, 1);
        }
      }

      .deleteUploaded {
        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    .uploadTrigger {
      margin-top: 10px;
      width: 86px;
      height: 86px;
      img {
        width: 86px;
        height: 86px;
      }
    }
  }

  .bottomBtn {
    background: transparent;
    position: absolute;
    bottom: 24px;
    padding: 0 24px;
    box-sizing: border-box;
    width: 100%;

    &.submitBtn {
      > div {
        height: 46px;
        line-height: 46px;
        background: rgba(67, 110, 255, 1);
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: white;
        border-radius: 23px;
        &.disabled {
          background: rgba(203, 213, 251, 1);
        }
      }
    }

    &.editBtn {
      > div {
        height: 46px;
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: rgba(67, 110, 255, 1);
        border-radius: 23px;
        border: 2px solid rgba(67, 110, 255, 1);
        box-sizing: border-box;
      }
    }
  }
}
</style>
